<template>
	<el-dialog class="dialog" title="查看" :visible.sync="visiable">
		<div class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="所属企业：">
							<el-input v-model="formData.entName" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="账号类型：">
							<el-input v-model="formData.isMaster==1?'主账号':'子账号'" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="用户账号：">
							<el-input v-model="formData.accountNo" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="所属部门：">
							<el-input v-model="formData.deptName" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="姓名：">
							<el-input v-model="formData.name" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="账号权限：">
							<el-input v-model="roles" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="手机：">
							<el-input v-model="formData.mobile" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="性别：">
							<el-input v-model="formData.sex==1?'男':formData.sex==2?'女':'保密'" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="邮箱：">
							<el-input v-model="formData.email" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="头像：">
							<el-image style="width: 50px; height: 50px" :src="headUrl" fit="fill"></el-image>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="账号状态：" v-if="dialogObj.type!=0">
							<el-input v-model="formData.status==1?'启用中':'已禁用'" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="备注：">
							<el-input type="textarea" v-model="formData.remarks" disabled>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				formData: {},
				headUrl: "",
				roles: ""
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.headUrl = "";
				this.roles="";
				this.get();
			},
			//获取数据
			get() {
				this.$get("/user-api/ent/user/get", {
					'id': this.dialogObj.id
				}).then((res) => {
					if (res.code == 1000) {
						this.formData = res.data
						if (this.formData.head) {
							this.headUrl = configApi.photoURL + this.formData.head
						}
						for (let i in this.formData.roles) {
							var item = this.formData.roles[i];
							this.roles += item.name;
							if (i < this.formData.roles.length - 1) {
								this.roles += ",";
							}
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}
	}
</style>
