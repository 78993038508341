<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="120px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="所属企业：" prop="entId">
							<el-select v-model="formData.entId" clearable :popper-append-to-body="false" placeholder="请选择" @change="selectEnt">
								<el-option v-for="item in ents" :key="item.id" :label="item.abbr" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12" v-if="this.dialogObj.type == 2">
						<el-form-item label="账号类型：" prop="isMaster">
							<el-radio-group v-model="formData.isMaster">
								<el-radio :label="1">主账号</el-radio>
								<el-radio :label="0">子账号</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="用户账号：" prop="accountNo"><el-input v-model="formData.accountNo"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="所属部门：">
							<el-cascader
								v-model="formData.deptId"
								clearable
								:show-all-levels="false"
								:props="{ value: 'id', label: 'name', children: 'child', checkStrictly: true, emitPath: false }"
								:options="departments"
							></el-cascader>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="姓名：" prop="name"><el-input v-model="formData.name"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="账号权限：" prop="roleIds">
							<el-select v-model="formData.roleIds" multiple collapse-tags clearable :popper-append-to-body="false" placeholder="请选择" @change="$forceUpdate()">
								<el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="手机：" prop="mobile"><el-input v-model="formData.mobile"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="密码："><el-input v-model="formData.pwd" show-password></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="性别：">
							<el-radio-group v-model="formData.sex">
								<el-radio :label="0">保密</el-radio>
								<el-radio :label="1">男</el-radio>
								<el-radio :label="2">女</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="头像：">
							<el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="BeforeUpload" :http-request="Upload">
								<el-image style="width: 50px; height: 50px" v-if="headUrl" :src="headUrl" fit="fill"></el-image>
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="邮箱："><el-input v-model="formData.email"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="账号状态：" prop="status">
							<el-switch v-model="formData.status" active-color="#4A99F6" :active-value="1" :inactive-value="0"></el-switch>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="备注："><el-input type="textarea" v-model="formData.remarks"></el-input></el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
import { dialogMixin } from '@m/dialogMixin';
import configApi from '@config/configApi';
export default {
	mixins: [dialogMixin],
	data() {
		return {
			title: '',
			loading: false,
			formData: {},
			headUrl: '',
			newFile: new FormData(),
			ents: [],
			roles: [],
			departments: [],
			rules: {
				entId: {
					required: true,
					message: '此项必填',
					trigger: 'change'
				},
				isMaster: {
					required: true,
					message: '此项必填',
					trigger: 'change'
				},
				accountNo: {
					required: true,
					message: '此项必填',
					trigger: 'change'
				},
				name: {
					required: true,
					message: '此项必填',
					trigger: 'change'
				},
				mobile: [
					{
						required: true,
						message: '此项必填',
						trigger: 'change'
					},
					{
						validator: (rule, value, callback) => {
							const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/;
							if (!value) {
								return callback(new Error('电话号码不能为空'));
							}
							setTimeout(() => {
								if (!Number.isInteger(+value)) {
									callback(new Error('请输入数字'));
								} else {
									if (phoneReg.test(value)) {
										callback();
									} else {
										callback(new Error('电话号码格式不正确'));
									}
								}
							}, 100);
						},
						trigger: 'change'
					}
				],
				status: {
					required: true,
					message: '此项必填',
					trigger: 'change'
				},
				roleIds: [
					{
						required: true,
						validator: (rule, value, callback) => {
							if (!value) {
								return callback(new Error('不能为空'));
							} else {
								callback();
							}
						},
						trigger: 'change'
					}
				]
			}
		};
	},
	props: {},
	watch: {},
	methods: {
		//打开弹窗
		open() {
			this.formData = {};
			this.headUrl = '';
			this.newFile = new FormData();
			this.ents = [];
			this.roles = [];
			this.departments = [];
			this.getEnts();
			this.get();
			this.$nextTick(() => {
				this.$refs.form.clearValidate();
			});
		},
		//获取企业列表
		getEnts() {
			this.$get('/backend-api/sys/ent/list', {
				size: 99
			}).then(res => {
				if (res.code == 1000) {
					this.ents = res.data.records;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		selectEnt(val) {
			this.formData.roleIds = [];
			this.formData.deptId = '';
			this.getRoles(val);
			this.getDepartments(val);
		},
		//获取账户角色
		getRoles(entId) {
			this.$get('/user-api/ent/role/list', {
				entId: entId,
				size: 99
			}).then(res => {
				if (res.code == 1000) {
					this.roles = res.data.records;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//获取部门
		getDepartments(entId) {
			this.$get('/user-api/ent/dept/tree', {
				entId: entId
			}).then(res => {
				if (res.code == 1000) {
					this.departments = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//上传前校验
		BeforeUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 2;
			const fileType = file.name.substring(file.name.lastIndexOf('.'));
			if (fileType != '.jpg' && fileType != '.JPG' && fileType != '.PNG' && fileType != '.png') {
				this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
			} else if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 10MB!');
			} else {
				if (file) {
					if(this.newFile.has("file")){
						this.newFile.delete("file");
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
			}
			return false;
		},
		//手动上传
		Upload() {
			this.loading = true;
			this.$upload(this.newFile)
				.then(res => {
					if (res.code == 1000) {
						this.formData.head = res.data;
						this.headUrl = configApi.photoURL + this.formData.head;
						this.loading = false;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch(error => {
					this.$message.error(error);
				});
		},
		//获取数据
		get() {
			if (this.dialogObj.type == 1) {
				this.title = '编辑';
				this.$get('/user-api/ent/user/get', {
					id: this.dialogObj.id
				}).then(res => {
					if (res.code == 1000) {
						this.selectEnt(res.data.entId);
						this.formData = res.data;
						//账号权限回显
						this.formData.roleIds = [];
						for (let item of this.formData.roles) {
							this.formData.roleIds.push(item.id);
						}
						//头像
						if (this.formData.head) {
							this.headUrl = configApi.photoURL + this.formData.head;
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			} else if (this.dialogObj.type == 2) {
				this.title = '新增用户';
			}
		},
		//提交表单
		submit() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					console.log(this.formData);
					this.loading = true;
					if (this.formData.deptId == null) {
						this.formData.deptId = '-1';
					}
					this.$postData('/user-api/ent/user/save', this.formData).then(res => {
						if (res.code == 1000) {
							this.loading = false;
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						} else {
							this.loading = false;
							this.$message.error(res.msg);
						}
					});
				} else {
					console.log(this.formData);
					return false;
				}
			});
		},
		//关闭弹窗
		close() {
			this.visiable = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.dialog {
	::v-deep.cascader {
		display: block;
	}
}

.avatar-uploader {
	::v-deep .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		width: 50px;
		height: 50px;
	}

	::v-deep .el-upload:hover {
		border-color: #409eff;
	}
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
}
</style>
